<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="用户端" name="first">
        <userTerminal :accountMap="accountMap" :buttonFlag="buttonFlag" @getAppListInfo="getAppListInfo" v-if="activeName == 'first'"/>
      </el-tab-pane>
      <el-tab-pane label="crm端" name="second">
        <crm :accountMap="accountMap" :buttonFlag="buttonFlag" @getAppListInfo="getAppListInfo" v-if="activeName == 'second'"/>
      </el-tab-pane>
      <el-tab-pane label="用户端支付" name="third">
        <userpay :accountMap="accountMap" :buttonFlag="buttonFlag" v-if="activeName == 'third'"/>
      </el-tab-pane>
      <el-tab-pane label="crm支付" name="fourthly">
        <crmpay :accountMap="accountMap" :buttonFlag="buttonFlag" v-if="activeName == 'fourthly'"/>
      </el-tab-pane>
<!--      <el-tab-pane label="登录设置" name="fifth" v-if="userInfo.main">-->
<!--        <authorizationSwitch :accountMap="accountMap" :buttonFlag="buttonFlag" v-if="activeName == 'fifth'"/>-->
<!--      </el-tab-pane>-->
    </el-tabs>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleD"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <div style="font-size: 20px">请上传正式运营商品后重新提交！</div>
      <span slot="footer" class="dialog-footer" style="text-align: right">
    <el-button type="primary" @click="jumpUpload">去上传</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import userTerminal from './components/userTerminal';
import crm from './components/crm';
import userpay from './components/userpay';
import crmpay from './components/crmpay';
// import authorizationSwitch from './components/authorizationSwitch';
import {getCompanyAccountList} from "@/api/authorization"
import {getGoodsInfoPC,} from "@/api/videoManage";

export default {
  data() {
    return {
      activeName: "first",
      accountMap: '',
      userInfo: '',
      dialogVisibleD: false,
      buttonFlag:false,
    };
  },
  components: {
    userTerminal,
    crm,
    userpay,
    crmpay,
    // authorizationSwitch
  },
  mounted() {
      this.userInfo = JSON.parse(localStorage.getItem('info'))
      this.getAppListInfo()
  },
  methods: {
    /**@method 加载商品列表 */
    async getGoodsInfoPC() {
      let data = {
        pageSize: this.goodsListPageSize,
        pageNum: this.goodsListCurrentPage,
      };
      try {
        const result = await getGoodsInfoPC(data);
        const {total, list} = JSON.parse(result?.data?.pageInfo);
        if (list.length == 0) {
          this.dialogVisibleD = true
          this.buttonFlag = true
        }else {
          this.buttonFlag = false
        }
      } catch (error) {
      }
      // const reslut
    },
    jumpUpload(){
      this.$router.push("/management");
    },
    handleClick(tab) {
      const {name} = tab;
      this.activeName = name;
    },
    async getAppListInfo() {
      const result = await getCompanyAccountList({appid: true})
      if (result.data) {
        if (result.data[0].state==0) {
          this.getGoodsInfoPC();
        }else {
          this.buttonFlag = false
        }
        let tmp = {};
        result && result.data.map(val => {
          tmp[val.accountType] = val;
        });
        this.accountMap = tmp;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog--center .el-dialog__footer{
  text-align: end;
}
</style>
